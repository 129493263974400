import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentCard from "../components/contentCard"

function OtherServices() {
  const image = useStaticQuery(graphql`
    query {
      money: file(relativePath: { eq: "money.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      deal: file(relativePath: { eq: "deal.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hands: file(relativePath: { eq: "hands.jpg" }) {
        childImageSharp {
          fixed(height: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Muut Palvelut" />
      <Container>
        <ContentCard
          anchorId="arvonmaaritys"
          imgProps={{
            fixed: image.deal.childImageSharp.fixed,
            alt: "Ihmisiä pöydän ympärillä keskustelemessa",
          }}
          title="Arvonmääritys"
          paragraphs={[
            `Olipa kyseessä yrityksen, sen osan, osakkeiden tai liiketoiminnan
              myynti tai ostaminen, ammattimainen arvonmääritys varmistaa oikean
              hintatason kaupan osapuolten keskustelulle. Arvonmääritystä
              voidaan tarvita myös yrityksen rahoitusneuvottelujen tueksi.`,

            `Lisäksi arvonmääritys luo pohjan suunniteltaessa ja toteutettaessa
              yrityksen osakepalkitsemiseen tai optioihin liittyviä
              kannustinjärjestelmiä.`,
            `
              Tyypillisimmät yrityksen arvonmäärityksen menetelmät ovat
              substanssiarvon määrittäminen sekä yrityksen liiketoiminnan arvon
              määrittäminen. Credos Oy:n kokemuksella voidaan valita sopivin
              arvonmäärityksen menetelmä kulloinkin käsillä olevaan
              tilanteeseen.
              `,
          ]}
        />
        <ContentCard
          anchorId="rahoitus"
          imgProps={{
            fixed: image.money.childImageSharp.fixed,
            alt: "Sentteja ja euroja kasoissa",
          }}
          title="Rahoitus"
          paragraphs={[
            `Yritystoiminnan tyypillisin haaste liittyy liiketoiminnassa
              tarvittavan rahoituksen varmistamiseen. Credos Oy:llä on laaja
              kokemus yrityksen rahoituskonsultoinnista. Etsimme yritykselle
              sopivimman rahoitusratkaisun olipa kyseessä oman pääoman ehtoinen
              rahoitus tai vieraan pääoman ehtoinen rahoitus.
              Myös erilaiset hybridiratkaisut, kuten vaihtovelkakirjalainat,
              voivat tulla kyseeseen.`,
            `
              Johtoajatuksemme rahoituksen järjestämisessä on yrittäjän etu:
              etsimme joustavimman ja kustannustehokkaimman ratkaisun pankkien
              ja muiden rahoittajien vaihtoehdoista.
              Tasapainoinen ja yrityksen kehityksen mukaan joustava rahoitus
              varmistaa yrityksen toiminnan kaikissa tilanteissa sekä vapauttaa
              yrittäjän keskittymään omaan erityisosaamiseensa.
              `
          ]}
        />
        <ContentCard
          anchorId="palkitseminen"
          imgProps={{
            fixed: image.hands.childImageSharp.fixed,
            alt: "Mies antaa ystävälleen kortin",
          }}
          title="Palkitseminen"
          paragraphs={[
            ` Motivoitunut henkilöstö on yrityksen tärkein voimavara.
              Työntekijöiden motivaatio on funktio useista työhön vaikuttavista
              tekijöistä, mutta rahallinen kannustaminen on motivaatiotekijöistä
              keskeisimpiä.`,
            `Credos Oy:llä on yli 15 vuoden kokemus yrityksen johdon ja
              henkilöstön palkitsemisjärjestelmien suunnittelusta ja
              toteuttamisesta. Hallitsemme osake- ja optiopohjaisten
              järjestelmien eri versioiden suunnittelun sekä koko henkilöstöä
              kannustavan henkilöstö- eli palkkiorahaston rakentamisen.`
          ]}
        />
      </Container>
    </Layout>
  )
}

export default OtherServices

const Container = styled.section`
  max-width: 1250px;
  margin: 50px auto;
`
